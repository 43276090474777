import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_investorPreferences.scss";
import ogImage from "../images/OG_Image.png";
import investorPreferenceHeatmap from "../images/investorPreferenceHeatmap.png";

const InvestorPreferences = () => (
  <Layout>
    <SEO
      title="Competitive Intelligence"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "competitive intelligence",
        "product",
        "feature",
        "analytics",
      ]}
      description="Investor preferences are constantly changing. Let Flowspring help you stay ahead of the curve."
    />
    <div className="content">
      <div className="investorPreferencesTitle">
        Sums of Flows
        {" "}
        <span className="notEqual">&nbsp;&#8800;&nbsp;</span>
        Investor Preferences
      </div>
      <div className="investorPreferencesBody">
        <div>
          <div className="investorPreferencesSection">
            <h2>Correctly assessing investor preferences is hard</h2>

            <p>
              Not every asset manager has a team of Ph.D. statisticians
              separating signal from noise in asset flow data. Unfortunately,
              simple, seemingly sensible analyses, like summing flows into
              categories or groupings in order to understand investor
              preferences, will lead you to staggeringly inaccurate
              conclusions. Only with a factor model can you properly
              disentangle all of the investor preferences that are embedded in
              observable asset flows.
            </p>
          </div>

          <div className="investorPreferencesSection">
            <h2>
              Flowspring does the statistical legwork with a factor model of
              organic growth
            </h2>
            <p>
              Factor models are routinely used by quantitatively minded
              investors to perform attribution analysis, dial-in specific risk
              allocations, and generally predict market movements. Factor
              models are
              {" "}
              <b>even more </b>
              suited to modeling organic growth rates there is no market
              mechanism which competes away factors that explain organic
              growth rates.
            </p>
          </div>
        </div>
        <img
          alt="Heatmap of factors, grouped by time."
          className="investorPreferenceHeatmap"
          src={investorPreferenceHeatmap}
        />
      </div>
    </div>
  </Layout>
);

export default InvestorPreferences;
